<template>
  <v-app>
    <v-main class='mainApp' :style="{background: $vuetify.theme.themes.light.background}">
      <router-view/>
    </v-main>
    <!-- <VueFbCustomerChat></VueFbCustomerChat> -->
  </v-app>
</template>

<script>
import axios from 'axios';

export default {
  name: 'App',
  metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      // all titles will be injected into this template
      titleTemplate: '%s ' + process.env.VUE_APP_PLATFORM_META_TITLE,
      htmlAttrs: {
        lang: 'bg'
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: 'Борса за куриерски услуги' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'keywords', content: 'куерски, услуги, куриерски услуги, борса, цени,' },
        { name: 'author', content: 'ПраткаБГ ЕООД' },
      ]
    },

  data: () => ({
    //
    userIP: '',
  }),
  methods: {
    async getUserIP() {
      let request = await axios.get('https://api.ipify.org?format=json')
      if(request != undefined) {
        this.userIP = request.ip
      }
      
    },
  },
  mounted() {
    const theme = localStorage.getItem("dark_theme");
    if (theme) {
        if (theme == "true") {
            this.$vuetify.theme.dark = true;
        } else {
            this.$vuetify.theme.dark = false;
        }
    }
    // this.getUserIP()
},
};
</script>

<style>
.mainApp{
  overflow:hidden;
}
</style>

