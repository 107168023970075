// import createPersistedState from "vuex-persistedstate"
import Vue from 'vue'
import Vuex from 'vuex'

// import users from './modules/users'
import parcels from './modules/parcels'
import language from './modules/language'

// console.log(process.env.VUE_APP_PLATFORM_URL)

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    platform: process.env.PLATFORM_URL,
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    // users,
    parcels,
    language
  },
  // plugins: [createPersistedState()]
})
