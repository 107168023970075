import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

import ro from './i18n/ro'
// import bg from './i18n/bg'

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#D70002',
        // primary_fade: linear-gradient(90deg, rgba(251,213,61,1) 0%, rgba(215,0,2,1) 100%),
        secondary: '#FBD53D',
        third: colors.grey.lighten3,
        accent: '#3692b6',
        text: '#1F506F',
        background: '#ffffff',
        error: colors.red.accent4,
        warning: colors.amber.darken2,
        info: colors.grey.darken1,
        success: colors.green.darken1,
      },
      dark: {
        primary: '#ffffff',
        secondary: '#101010',
        accent: '#1F506F',
        text: '#ffffff',
        background: '#151515',
        error: colors.red.accent4,
        warning: colors.amber.darken4,
        info: colors.grey.darken3,
        success: colors.green.darken3,
      },
    },
    dark: false,
  },
  lang: {
    locales: { ro },
    current: 'ro',
  },
});
// 101010 - 1F506F