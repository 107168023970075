// import axios from 'axios';

  const state = {
    lang: { title: 'English', code: 'en' },
    locale: {
        en: {
          header_menu: {
            send_parcel: 'Send',
            track_parcel: 'Track',
            about_us: 'About us',
            faq: 'FAQ',
            contact: 'Contact us',
            login: 'Login',
          },
          hero_section: {
            title: 'Your broken in energetics',
            body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            cta: 'Learn more',
          },
          about_section: {

          },
          services_section: {
            
          },
          contact_sections: {

          },
          footer_menu: {
            cookies: 'Cookie Policy',
            tos: 'Terms of Service',
            gdrp: 'Privacy Policy',
          }
        },
        bg: {
            header_menu: {
              send_parcel: 'Изпрати',
              track_parcel: 'Проследи',
              about_us: 'За нас',
              faq: 'ЧЗВ',
              contact: 'Контакти',
              login: 'Вход',
            },
            hero_section: {
                title: 'Вашият посредник в сферата на енергетиката',
                body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                cta: 'Научи повече',
            },
            about_section: {

            },
            services_section: {
            
            },
            contact_sections: {

            },
            footer_menu: {
                cookies: 'Бисквитки',
                tos: 'Общи условия',
                gdrp: 'Политика за личните данни',
            }
        },
    }
  }
  
  const getters = {
    userLang: state => state.lang,
  }
  
  const actions = {
    async changeLanguage({commit}, lang) {
      commit('setUserLang', lang)
    },
  }
  
  const mutations = {
    setUserLang(state, lang) {
      state.lang = lang
    },
  }
  
  export default {
    state,
    getters,
    actions,
    mutations
  }