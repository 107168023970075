import { render, staticRenderFns } from "./HomeToS.vue?vue&type=template&id=680f2705&scoped=true&"
import script from "./HomeToS.vue?vue&type=script&lang=js&"
export * from "./HomeToS.vue?vue&type=script&lang=js&"
import style0 from "./HomeToS.vue?vue&type=style&index=0&id=680f2705&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "680f2705",
  null
  
)

export default component.exports