<template>
    <v-container grid-list-lg id='gdrp' align='center' style='margin-bottom:15px;'>
        
        <v-layout col wrap>
    
            <v-flex xs12 sm12 md12>
                <!-- Parcel Start -->
                <v-card elevation="4" align='center' color='secondary'>
                    <v-card-title class='text-md-h4 text-sm-h6 font-weight-black primary--text text-xs-center my-1'>
                      {{ $vuetify.lang.t("$vuetify.prohibited_international_parcels.title") }}
                    </v-card-title>

                    <v-card-text>
                        <v-list color="secondary">
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title class="font-weight-bold">
                                                1.{{ $vuetify.lang.t('$vuetify.prohibited_international_parcels.section_1.title') }}
                                            </v-list-item-title>
                                            <v-list-item-text v-for="item in textItemizer($vuetify.lang.t('$vuetify.prohibited_international_parcels.section_1.body'))" :key='item'>
                                                {{ item }}
                                            </v-list-item-text>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title class="font-weight-bold">
                                                2.{{ $vuetify.lang.t('$vuetify.prohibited_international_parcels.section_2.title') }}
                                            </v-list-item-title>
                                            <v-list-item-text v-for="item in textItemizer($vuetify.lang.t('$vuetify.prohibited_international_parcels.section_2.body'))" :key='item'>
                                                {{ item }}
                                            </v-list-item-text>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title class="font-weight-bold">
                                                3.{{ $vuetify.lang.t('$vuetify.prohibited_international_parcels.section_4.title') }}
                                            </v-list-item-title>
                                            <v-list-item-text v-for="item in textItemizer($vuetify.lang.t('$vuetify.prohibited_international_parcels.section_4.body'))" :key='item'>
                                                {{ item }}
                                            </v-list-item-text>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
export default {
    name: 'HomeGDPR',
    data() {
        return {
            section_list: ['$vuetify.gdpr.section_1', '$vuetify.gdpr.section_2', '$vuetify.gdpr.section_3', '$vuetify.gdpr.section_4', '$vuetify.gdpr.section_5', '$vuetify.gdpr.section_6', '$vuetify.gdpr.section_7', '$vuetify.gdpr.section_8', '$vuetify.gdpr.section_9', '$vuetify.gdpr.section_10', '$vuetify.gdpr.section_11', '$vuetify.gdpr.section_12', '$vuetify.gdpr.section_13', '$vuetify.gdpr.section_14'],
            ordered_list: [{title: "$vuetify.gdpr.b1_title", answer: "$vuetify.gdpr.b1_answer"},{title: "$vuetify.gdpr.b2_title", answer: "$vuetify.gdpr.b2_answer"},{title: "$vuetify.gdpr.b3_title", answer: "$vuetify.gdpr.b3_answer"},{title: "$vuetify.gdpr.b4_title", answer: "$vuetify.gdpr.b4_answer"},{title: "$vuetify.gdpr.b5_title", answer: "$vuetify.gdpr.b5_answer"},{title: "$vuetify.gdpr.b6_title", answer: "$vuetify.gdpr.b6_answer"},{title: "$vuetify.gdpr.b7_title", answer: "$vuetify.gdpr.b7_answer"},{title: "$vuetify.gdpr.b8_title", answer: "$vuetify.gdpr.b8_answer"},{title: "$vuetify.gdpr.b9_title", answer: "$vuetify.gdpr.b9_answer"}],
            analytics_list: ['$vuetify.gdpr.analytics_1', '$vuetify.gdpr.analytics_2', '$vuetify.gdpr.analytics_3', '$vuetify.gdpr.analytics_4', '$vuetify.gdpr.analytics_5', '$vuetify.gdpr.analytics_6', '$vuetify.gdpr.analytics_7', '$vuetify.gdpr.analytics_8', '$vuetify.gdpr.analytics_9', '$vuetify.gdpr.analytics_10', '$vuetify.gdpr.analytics_11' ,'$vuetify.gdpr.analytics_12']
        };
    },
    computed: {
    },
    methods: {
        textItemizer(string) {
            let array = string.split(";");
            return array;
        },
    }
};
</script>

<style scoped>
</style>