<template>
  <div>
    <app-navigation></app-navigation>
    <v-fab-transition>
      <v-btn
        color="secondary"
        fixed
        bottom
        left
        fab
        v-scroll="onScroll"
        v-show="fab"
        @click="toTop"
        
      >
        <v-icon color='primary'>mdi-arrow-up</v-icon>
      </v-btn>
    </v-fab-transition>

    <!-- <v-speed-dial
      v-model="fab"
      fixed
      bottom
      left
      direction="top"
      open-on-hover
      :transition="transition"
    >
      <template v-slot:activator>
        <v-btn
          v-model="fab"
          color="primary"
          dark
          fab
        >
          <v-icon v-if="fab">
            mdi-close
          </v-icon>
          <v-icon v-else>
            mdi-package
          </v-icon>
        </v-btn>
      </template>
      <v-btn
        fab
        dark
        small
        color="primary"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-btn
        fab
        dark
        small
        color="secondary"
      >
        <v-icon>mdi-arrow-up</v-icon>
      </v-btn>
    </v-speed-dial> -->
    
    <router-view v-slot=" { Component }">
      <v-expand-transition>
        <component :is="Component"></component>
      </v-expand-transition>
    </router-view>

    <home-footer></home-footer>
  </div>
</template>

<script>
// Relative Components
import Vue from 'vue'
import AppNavigation from '@/components/AppNavigation_v1.vue'
import HomeFooter from '@/components/home/HomeFooter'

// Node Modules
// import VueIfBot from 'vue-if-bot'
// import CookieLaw from 'vue-cookie-law'

export default {
  name: 'Website',

  metaInfo: {
    // title: ''
  },

  components: {
    AppNavigation,
    // VueIfBot,
    // CookieLaw,
    HomeFooter,
  },

  computed: {
  },

  data: () => ({
    transition: 'slide-y-reverse-transition',
    //
    fab: false,
    cookie_concent: false,
    cookieLaw_text_bg: 'Ние използваме бисквитки на нашия уебсайт, за да ви предоставим най-подходящото изживяване, като запомним вашите предпочитания и повтаряме посещенията. С натискането на „Приемам“ вие се съгласявате с използването на ВСИЧКИ бисквитки.',
    cookieLaw_btn_bg: 'Приемам',

    // Footer
    footer_icons: [
    { name:"facebook", icon:"mdi-facebook", href:"https://www.facebook.com/www.Pratka.net"},
    { name:"instagram", icon:"mdi-instagram", href:"https://www.instagram.com/pratkabg_com/"},
    ],
  }),

  methods: {
    toTop () {
      this.$vuetify.goTo('#start')
    },
    onScroll (e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset ||   e.target.scrollTop || 0
      this.fab = top > 100
    },
    acceptCookies() {
        this.cookie_concent = true
        localStorage.setItem("cookie_concent", this.cookie_concent.toString());      
      },
    disableTracking() {
      this.$ga.disable();
      alert('Tracking disabled');
    }
  },

  mounted() {
    Vue.analytics.fbq.event('ViewContent', {
      content_name: 'LandingPage'
    })
    const cookieConcent = localStorage.getItem("cookie_concent");
    if (cookieConcent) {
        if (cookieConcent == "true") {
            this.cookie_concent = true;
        } else {
            this.cookie_concent = false;
        }
    }
  },
}
</script>
<style scoped>
h1 {
  font-size: 2.3rem;
}

a {
  font-size: 1.1rem;
  color: gray;
}

a:hover {
  transition: ease-in 0.2s;
  color: #D70002;
}

.iconHover:hover {
  transition: ease-in 0.2s;
  color: #D70002;
}

.toolbar {
  background-color: antiquewhite;
  color:aliceblue;
}

</style>
