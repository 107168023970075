<template>
  <div>
      <home-prohibited></home-prohibited>
      <!-- <home-footer></home-footer> -->
  </div>
</template>

<script>
// Relative Components
// import HomeFooter from '@/components/home/HomeFooter'
import HomeProhibited from '../components/home/HomeProhibited.vue'

// Node Modules

  export default {
    name: 'ProhibitedStocks',
    components: {
      HomeProhibited,
    },

    computed: {
    },

    data: () => ({
    }),

    methods: {
    },

    mounted() {
    },
  }
</script>
<style scoped>

</style>
