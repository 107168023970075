<template>
    <v-container class="mb-12 md-2">
      <v-row class="align-center">
        <v-col cols="12" lg="6" class=" align-self-center order-1 order-lg-0 pr-10">
        <v-row class="text-center text-lg-left d-block mb-7" >
            <v-col class="text-md-h3 text-sm-h4 font-weight-black primary--text text-xs-center ma-0 pa-0">
              {{ $vuetify.lang.t('$vuetify.home.onlinestore.title') }}
            </v-col>
            <v-col class=" my-3 pa-0 text-body-1 font-weight--regular  text-black">
              {{ $vuetify.lang.t('$vuetify.home.onlinestore.body') }}
            </v-col>
              <v-btn 
                color='primary'
                block
                target="_blank" 
                href="https://pratka.biz">
                {{ $vuetify.lang.t('$vuetify.home.onlinestore.btn') }}
            </v-btn>
          </v-row>

          <v-card
          class="rounded-xl d-inline-flex align-center justify-center packages-available__country-item tab__item nav-item py-1 mx-1"
          height="90" width="100%" style="z-index:999999;background: #0000007a">
            <v-avatar v-for="(item, index) in onlineStore" :key="index" class="mx-3 rounded-0 packages-available__country-item tab__item nav-item py-1 mx-1">
              <v-img :src="item.img" alt="Store" class=""></v-img>
            </v-avatar>
          </v-card>
        </v-col>


        <v-col cols="12" lg="6" class="order-0 order-lg-1">
          <v-img src="@/assets/images/d4_transp_ro.png" class=""
                 alt="Paquetería"></v-img>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>


export default {
  name: 'OnlineStore',
  props: {
    msg: String
  },
  components: {

  },
  data() {
    return {

      onlineStore: [
        {
          img: 'https://s3.us-east-2.amazonaws.com/enviapaqueteria/uploads/landing/images/integrations/logos/ecommerce/shopify.svg',
        },
        {
          img: 'https://s3.us-east-2.amazonaws.com/enviapaqueteria/uploads/landing/images/integrations/logos/ecommerce/woocommerce.svg',
        },
        {
          img: 'https://s3.us-east-2.amazonaws.com/enviapaqueteria/uploads/landing/images/integrations/logos/ecommerce/prestashop.svg',
        },
        {
          img: 'https://s3.us-east-2.amazonaws.com/enviapaqueteria/uploads/landing/images/integrations/logos/ecommerce/kometia.svg',
        },
        {
          img: 'https://s3.us-east-2.amazonaws.com/enviapaqueteria/uploads/landing/images/integrations/logos/ecommerce/magento1.svg',
        },
        // {
        //   img: 'https://s3.us-east-2.amazonaws.com/enviapaqueteria/uploads/landing/images/integrations/logos/ecommerce/bcommerce.svg',
        // },
        // {
        //   img: 'https://s3.us-east-2.amazonaws.com/enviapaqueteria/uploads/landing/images/integrations/logos/ecommerce/wix.svg',
        // },
        // {
        //   img: 'https://s3.us-east-2.amazonaws.com/enviapaqueteria/uploads/landing/images/integrations/logos/ecommerce/squarespace.svg',
        // },
        // {
        //   img: 'https://s3.us-east-2.amazonaws.com/enviapaqueteria/uploads/landing/images/integrations/logos/ecommerce/magento2.svg',
        // },
        // {
        //   img: 'https://s3.us-east-2.amazonaws.com/enviapaqueteria/uploads/landing/images/integrations/logos/ecommerce/amazon.svg',
        // },
        // {
        //   img: 'https://s3.us-east-2.amazonaws.com/enviapaqueteria/uploads/landing/images/integrations/logos/ecommerce/ebay.svg',
        // },
        // {
        //   img: 'https://s3.us-east-2.amazonaws.com/enviapaqueteria/uploads/landing/images/integrations/logos/ecommerce/mercadolibre.svg',
        // },
        // {
        //   img: 'https://s3.us-east-2.amazonaws.com/enviapaqueteria/uploads/landing/images/integrations/logos/ecommerce/shiphero.svg',
        // },
        // {
        //   img: 'https://s3.us-east-2.amazonaws.com/enviapaqueteria/uploads/landing/images/integrations/logos/ecommerce/tradegecko.svg',
        // },
        // {
        //   img: 'https://s3.us-east-2.amazonaws.com/enviapaqueteria/uploads/landing/images/integrations/logos/ecommerce/zoho.svg',
        // },
        // {
        //   img: 'https://s3.us-east-2.amazonaws.com/enviapaqueteria/uploads/landing/images/integrations/logos/ecommerce/3dcart.svg',
        // },
        // {
        //   img: 'https://s3.us-east-2.amazonaws.com/enviapaqueteria/uploads/landing/images/integrations/logos/ecommerce/opencart.svg',
        // },
        // {
        //   img: 'https://s3.us-east-2.amazonaws.com/enviapaqueteria/uploads/landing/images/integrations/logos/ecommerce/vtex.svg',
        // },
        // {
        //   img: 'https://s3.us-east-2.amazonaws.com/enviapaqueteria/uploads/landing/images/integrations/logos/ecommerce/salesforce.svg',
        // },
        // {
        //   img: 'https://s3.us-east-2.amazonaws.com/enviapaqueteria/uploads/landing/images/integrations/logos/ecommerce/jumpseller.svg',
        // },
        // {
        //   img: 'https://s3.us-east-2.amazonaws.com/enviapaqueteria/uploads/landing/images/integrations/logos/ecommerce/ecwid.svg',
        // },
        // {
        //   img: 'https://s3.us-east-2.amazonaws.com/enviapaqueteria/uploads/landing/images/integrations/logos/ecommerce/etsy.svg',
        // },
        // {
        //   img: 'https://s3.us-east-2.amazonaws.com/enviapaqueteria/uploads/landing/images/integrations/logos/ecommerce/claroshop.svg',
        // },
        // {
        //   img: 'https://s3.us-east-2.amazonaws.com/enviapaqueteria/uploads/landing/images/integrations/logos/ecommerce/walmart.svg',
        // },
        // {
        //   img: 'https://s3.us-east-2.amazonaws.com/enviapaqueteria/uploads/landing/images/integrations/logos/ecommerce/shoplazza.svg',
        // },
        // {
        //   img: 'https://s3.us-east-2.amazonaws.com/enviapaqueteria/uploads/landing/images/integrations/logos/ecommerce/hybris.svg',
        // },
        // {
        //   img: 'https://s3.us-east-2.amazonaws.com/enviapaqueteria/uploads/landing/images/integrations/logos/ecommerce/allegro.svg',
        // },
        // {
        //   img: 'https://s3.us-east-2.amazonaws.com/enviapaqueteria/uploads/landing/images/integrations/logos/ecommerce/cscart.svg',
        // },
        // {
        //   img: 'https://s3.us-east-2.amazonaws.com/enviapaqueteria/uploads/landing/images/integrations/logos/ecommerce/ecart.svg',
        // },
        // {
        //   img: 'https://s3.us-east-2.amazonaws.com/enviapaqueteria/uploads/landing/images/integrations/logos/ecommerce/multivende.svg',
        // },
        // {
        //   img: 'https://s3.us-east-2.amazonaws.com/enviapaqueteria/uploads/landing/images/integrations/logos/ecommerce/nopcommerce.svg',
        // },
        // {
        //   img: 'https://s3.us-east-2.amazonaws.com/enviapaqueteria/uploads/landing/images/integrations/logos/ecommerce/quickbooks.svg',
        // },
        // {
        //   img: 'https://s3.us-east-2.amazonaws.com/enviapaqueteria/uploads/landing/images/integrations/logos/ecommerce/unicommerce.svg',
        // },
        // {
        //   img: 'https://s3.us-east-2.amazonaws.com/enviapaqueteria/uploads/landing/images/integrations/logos/ecommerce/vendhq.svg',
        // },
        // {
        //   img: 'https://s3.us-east-2.amazonaws.com/enviapaqueteria/uploads/landing/images/integrations/logos/ecommerce/verskis.svg',
        // },
        // {
        //   img: 'https://s3.us-east-2.amazonaws.com/enviapaqueteria/uploads/landing/images/integrations/logos/ecommerce/weebly.svg',
        // },
      ],

    }
  },
  methods: {},
}
</script>

<style lang="scss" scoped>


</style>
