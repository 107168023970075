import axios from 'axios';

import Vue from 'vue'
import VueMeta from 'vue-meta'
// import DisableAutocomplete from 'vue-disable-autocomplete'
// import VuePhoneNumberInput from 'vue-phone-number-input';
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
// import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import VueGtag from "vue-gtag";


import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

// Google Analytics
// import VueAnalytics from 'vue-analytics'
import Pixel from 'vue-analytics-facebook-pixel'
import VueFbCustomerChat from 'vue-fb-customer-chat'

// import './assets/scss/main.scss'
// export const serverURL = 'VUE_APP_SERVER_URL'

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_SERVER_URL
export const platform_url = process.env.PLATFORM_URL

Vue.config.productionTip = false
Vue.use(VueMeta)
Vue.use(VueTelInput)
Vue.use(Pixel)
Vue.use(VueFbCustomerChat, {
  page_id: 119778901778640, //  change 'null' to your Facebook Page ID,
  theme_color: '#333333', // theme color in HEX
  locale: 'en_US', // default 'en_US'
})

Vue.use(VueGtag, {
  config: { id: "G-ERY1R13M7E" }
});

// COLET1 ONLY - G-ERY1R13M7E
Vue.analytics.fbq.init('410962917777700', {
  em: 'user@mail.com'
})

// Vue.use(VuePhoneNumberInput);
// Vue.use(DisableAutocomplete);

Vue.use(VueTelInputVuetify, {
  vuetify,
});

// NEW
axios.interceptors.response.use(undefined, function (error) {
  if (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      store.dispatch('logOut')
      return router.push('/')
    }
  }
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
