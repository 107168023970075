<template>
    <v-container id='heroTitle' class="pt-1 pb-0">
        <v-row no-gutters align="center" justify="center" class='text-h6 font-weight-bold primary--text'>
            <!-- <v-col class='text-h6 font-weight-bold primary--text'> -->
                <!-- Ползвайте от 25% до 70% отстъпка за вътрешни и международни пратки! -->
                {{ $vuetify.lang.t('$vuetify.home.hero_title') }}
            <!-- </v-col> -->
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'HomeHero',
    data() {
        return {
            selectedItem: 1,
            items: [
                { text: 'Ежемесечен Cashback от 15%', icon: 'mdi-checkbox-marked' },
                { text: 'Автоматично приготвяне на товарителници', icon: 'mdi-checkbox-marked' },
            ],
            parcel1_items: [
                { text: 'Палети/Колети', icon: 'mdi-checkbox-marked' },
                { text: 'Личен багаж', icon: 'mdi-checkbox-marked' },
            ],
            hero_carousel_images: [
                {
                    src: require('../../assets/images/partners/evropat.jpg'),
                },
                // {
                //    src: require('../../assets/images/partners/fedex.jpg'),
                // },
                {
                    src: require('../../assets/images/partners/hermes.jpg'),
                },
                {
                    src: require('../../assets/images/partners/econt.jpg'),
                },
                {
                    src: require('../../assets/images/partners/pricing.jpg'),
                },
            ],
        };
    },
};
</script>

<style scoped>
.home-hero {
    background-image: url('../../assets/images/bgs/bg-3.svg') fixed; 
    background-size: cover;
    width: 100%;
    height: 100%;
}

/* .hero-background{
    max-height:300px;
} */
.partner-carousel{
    height: 200px;
}


.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  /* max-width:200px; */
  max-height:200px;
}

/* .hero-container {
    margin-top: 10px;
    max-height: 50vh;
} */

.hero-text {
    /* font-family: 'Roboto'; */
    /* color: black; */
    font-style: bold;
    font-weight: 700;
    /* font-size: 34px; */
    line-height: 32px;
    /* display: flex; */
    /* align-items: center; */
    text-align: left;
    text-justify: newspaper;
}

.hero-action {
    margin-top:20px;
    /* font-family: 'Roboto'; */
    font-size: 32px;
    font-weight: 700;
    text-align: justify;
}
@media (min-width: 950px) {
    .hero-container {
        max-height: 50vh;
    }
    .partnersCarousel {
        width: 300px;
        height: 200px; 
    }
}
@media (min-width: 768px) and (max-width:950px){
    .hero-container {
        max-height: 45vh;
    }
    .hero-action {
        margin-top:20px;
        font-size: 22px;
        font-weight: 700;
        text-align: justify;
    }
    .partnersCarousel {
        width: 300px;
        height: 200px; 
    }
    .center {
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width:300px;
        max-height:auto;
    }
}
@media (min-width: 420px) and (max-width:768px){
    .hero-container {
        max-height: 50vh;
    }
    .partnersCarousel {
        width: 300px;
        height: 200px; 
    }
    .hero-action {
        margin-top:20px;
        font-size: 22px;
        font-weight: 700;
        text-align: justify;
    }
    .partnersCarousel {
        width: 150px;
        height: 100px; 
    }
    .center {
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width:200px;
        max-height:auto;
    }
}
@media (min-width: 280px) and (max-width: 420px){
    .hero-container {
        max-height: 60vh;
    }
    .partner-carousel{
        height: 100px;
    }
    .hero-action {
        margin-top:10px;
        font-size: 24px;
        font-weight: 700;
        text-align: justify;
    }
    .partnersCarousel {
        width: 150px;
        height: 100px; 
    }
    .center {
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width:300px;
        max-height:auto;
        flex-wrap: wrap;
    }
}
</style>